import React from 'react';
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import './ContactUs.css';

function Contact() {
  return ( 
    <>
      <section className="FirstHeader">
        <div className="Contact">
          <h1>Contact Us</h1>
        <div className="nav-links">
          <Link to="/" className="nav-link"><span style={{fontSize: '1.4rem'}}>Home</span></Link>
          <span style={{ color: '#fb8e40', fontSize: '1.4rem'}}> | Contact Us</span>
        </div>
        </div>
      </section>
      <section className="SecondHeader">
        <div>
          <h4>CONTACT US</h4>
          <h1>Contact with Skys Soft Solutions</h1>
        </div>
      </section>
      <section className="ThirdHeader">
        <div>
          <h1>
            <span className="align-left">Evolve Your Business</span>
            <span className="align-left">With Us</span>
          </h1>
          <div className="contact-boxes">
            <div className="contact-box">
              <FaMapMarkerAlt className="contact-icon" />
              <div className="contact-text">
                <div>Reach Us</div>
                <div>USA</div>
              </div>
            </div>
            <div className="contact-box">
              <FaEnvelope className="contact-icon" />
              <div className="contact-text">
                <div>Mail Us</div>
                <div>info@skyssoftsolutions.com</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="footer-content">
          <p>© 2024 SkysSoft Solutions Inc</p>
        </div>
      </footer>
    </>
  );
}

export default Contact;
