import React from 'react';
import { Link } from 'react-router-dom';
import './OurCompany.css';
import businessgrowth from './images/businessgrowth.webp';

function OurCompany() {
  return (
    <>
      <section className="HeaderOne">
        <div className="OurCompany">
          <h1><span style={{fontSize: '4rem'}}>Our Company</span></h1>
          <div className="nav-links">
            <Link to="/" className="nav-link">
              <span style={{ fontSize: '1.4rem' }}>Home</span>
            </Link>
            <span style={{ color: '#fb8e40', fontSize: '1.4rem' }}>
              {' '}
              | Our Company
            </span>
          </div>
        </div>
      </section>

      <section className="HeaderTwo">
        <div className="pictureAndtext">
          {/* Image box */}
          <div className="info_box">
            <img src={businessgrowth} alt="Service 2" className="info_box-image" />
            <div className="details">
              <span className="title">GROW</span>
              <span className="info">Grow your business with our company's valuable staff</span>
            </div>
          </div>

          {/* First paragraph (Our Mission) */}
          <div className="company_info">
            <h2><span style={{ color: '#fb8e40'}}>We</span> are one of the <span style={{color: '#fb8e40'}}>leading</span> IT solutions providers</h2>
            <p>
              <strong><span style={{ color:'#fb8e40'}}>Our mission</span></strong>: Our mission is to bridge the gap between technology and talent, connecting innovative businesses with exceptional IT professionals who drive success. We strive to deliver personalized recruitment solutions, fostering long-term relationships built on trust, expertise, and a passion for empowering the tech industry's future. Through our dedication to excellence, agility, and customer satisfaction, we aim to be the trusted partner of choice for IT talent acquisition, propelling businesses forward in an ever-evolving digital landscape.
            </p>
          </div>
        </div>

        {/* The next two paragraphs */}
        <div className="additional_info">
          <p>
            <strong><span style={{ color: '#fb8e40'}}>Hire the right IT talent</span></strong>: Our team of seasoned recruitment specialists has deep industry knowledge, ensuring we identify and attract top-tier candidates tailored to your specific needs. With a comprehensive database of diverse IT professionals—from software developers and data analysts to cybersecurity experts and project managers—Sky Soft Solutions is your go-to resource for all your recruitment needs. Our efficient recruitment process saves you time and resources. From job posting to candidate screening, interviews, and final placements, we handle it all seamlessly. We stay ahead of industry trends to provide insights on the latest technologies and skill sets, equipping you to make informed hiring decisions.
          </p>
          <p><b>Permanent Recruitment:</b> Find the right long-term hire to fit your company's needs.<br /></p>
          <p><b>Contract Staffing:</b> Flexible solutions for project-based hiring.<br /></p>
          <p><b>Executive Search:</b> Identify and recruit top-level IT executives.<br /></p>
          <p><b>Talent Mapping:</b> Gain insight into talent availability in specific markets or sectors.<br /></p>

          <p>
            <strong><span style={{ color: '#fb8e40'}}>Building long-term relationships</span></strong>: We believe in building long-term relationships. Our approach involves understanding your company culture, values, and team dynamics to find candidates that best fit your organization.
          </p>
        </div>
      </section>
      <footer className="footer">
        <div className="footer-content">
          <p>© 2024 SkysSoft Solutions Inc</p>
        </div>
      </footer>
    </>
  );
}

export default OurCompany;
