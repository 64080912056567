// App.js
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from 'react-router-dom';
import Home from './Home.js';
import Contact from './ContactUs.js';
import OurCompany from './OurCompany.js';
import Services from './Services.js';
import './App.css';

// ScrollToTop component
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setMenuOpen(!menuOpen);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const closeMenu = (e) => {
      if (!e.target.closest('.App-nav')) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', closeMenu);
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <header className="App-header">
          <nav className="App-nav">
            <div className="App-logo">
              <Link to="/" className="LogoStyle">
                SkysSoft Solutions
              </Link>
            </div>
            <button
              className="menu-toggle"
              onClick={toggleMenu}
            >
              ☰
            </button>
            <ul className={`App-nav-links ${menuOpen ? 'active' : ''}`}>
              <li>
                <Link to="/" onClick={toggleMenu}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/OurCompany" onClick={toggleMenu}>
                  Our Company
                </Link>
              </li>
              <li>
                <Link to="/Services" onClick={toggleMenu}>
                  Services
                </Link>
              </li>
              <li>
                <Link to="/Contact" onClick={toggleMenu}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </nav>
        </header>

        <div className="App-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/OurCompany" element={<OurCompany />} />
            <Route path="/Services" element={<Services />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
