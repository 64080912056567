import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Services from './Services.js';
import backgroundImage from './images/homepageimage.webp';
import placeholderhomeimage from './images/placeholderhomeimage.jpg'
import whitebackground from './images/whitebackground.jpg'
import whiteimage from './images/whiteimage.jpg';
import timenmoney from './images/timenmoney.webp';
import timeimage from './images/timeimage.jpg'
import timeimage2 from './images/timeimage2.jpg'
import networking from './images/networking.jpg';
import futureofIT from './images/futureofIT.webp';

function Home() {
  return (
    <div className="Home">
      <section className="Home-hero">
        <div className="Home-content">
          <h1 className="animated-text">
          <p>
            <span style={{ color: '#fb8e40'}}>IT Staffing</span> 
            <span> Services</span>
          </p>

            <span style={{ color: '#fb8e40' }}>Welcome to </span> SkysSoft Solutions
          </h1>
          <p className="animated-text">Your gateway to IT talent</p>
          <button className="Home-button">
            <Link to='/Services'>
            <span className="button-text">Services</span>
            </Link>
            <span className="arrow">→</span>
          </button>
        </div>
      </section>
      <section className="Home-body">
        <h2>
          Employee Friendly <span className="fontweight-normal">Service</span>
        </h2>
        <div className="info-boxes">
          <div className="info-box">
            <Link to='/Services'>
            <img src={timeimage2} alt="Service 1" className="info-box-image" />
            </Link>
            <div className="details">
              <span className="title">Save time and resources</span>
              <span className="info">Efficient solutions to optimize your resources.</span>
            </div>
          </div>
          <div className="info-box">
            <Link to='/Services'>
            <img src={networking} alt="Service 2" className="info-box-image" />
            </Link>
            <div className="details">
              <span className="title">Connect with the right people</span>
              <span className="info">Build valuable networks for your business growth.</span>
            </div>
          </div>
          <div className="info-box">
            <Link to='Services'>
            <img src={futureofIT} alt="Service 3" className="info-box-image" />
            </Link>
            <div className="details">
              <span className="title">Hire the right IT talent</span>
              <span className="info">Innovative approaches to drive your hires.</span>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="footer-content">
          <p>© 2024 SkysSoft Solutions Inc</p>
        </div>
      </footer>
    </div>
    
  );
}

export default Home;
