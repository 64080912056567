import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './Services.css';
import services from './images/Services2.jpeg'

function Services() {
    const [dropdownVisible, setDropdownVisible] = useState(null);

    const toggleDropdown = (index) => {
        setDropdownVisible(dropdownVisible === index ? null : index);
    };

    return (
        <>
        <section className="HeaderOne-Services">
        <div className="OurCompany">
          <h1><span style={{fontSize: '4rem'}}>Our Services</span></h1>
          <div className="nav-links">
            <Link to="/" className="nav-link">
              <span style={{ fontSize: '1.4rem' }}>Home</span>
            </Link>
            <span style={{ color: '#fb8e40', fontSize: '1.4rem' }}>
              {' '}
              | Our Services
            </span>
          </div>
        </div>
      </section>

      <section className="HeaderTwo-Services">
        
      <section className="services-list-section">
        <p><span style={{ color:'#fb8e40'}}>WE ARE EXPERIENCED</span></p>
        <h2>SkysSoft Solutions provides an array of services to help your business grow.</h2>
        <div className="services-list">
          <div
            className={`service-item ${dropdownVisible === 0 ? 'active' : ''}`}
            onClick={() => toggleDropdown(0)}
          >
            <span>IT Talent Acquisition</span>
            <span className="service-item-plus">{dropdownVisible === 0 ? '-' : '+'}</span>
          </div>
          {dropdownVisible === 0 && (
            <div className="dropdown-content">
              <p>We source and place top IT talent in permanent, contract, or temporary positions.</p>
            </div>
          )}

          <div
            className={`service-item ${dropdownVisible === 1 ? 'active' : ''}`}
            onClick={() => toggleDropdown(1)}
          >
            <span>Technical Recruitment</span>
            <span className="service-item-plus">{dropdownVisible === 1 ? '-' : '+'}</span>
          </div>
          {dropdownVisible === 1 && (
            <div className="dropdown-content">
              <p>We specialize in finding candidates with specialized technical skills, including software development, data analytics, cybersecurity, and more.</p>
            </div>
          )}

          <div
            className={`service-item ${dropdownVisible === 2 ? 'active' : ''}`}
            onClick={() => toggleDropdown(2)}
          >
            <span>IT Project Staffing</span>
            <span className="service-item-plus">{dropdownVisible === 2 ? '-' : '+'}</span>
          </div>
          {dropdownVisible === 2 && (
            <div className="dropdown-content">
              <p>We provide skilled IT professionals for project-based work, ensuring your projects are completed on time and within budget.</p>
            </div>
          )}

          <div
            className={`service-item ${dropdownVisible === 3 ? 'active' : ''}`}
            onClick={() => toggleDropdown(3)}
          >
            <span>IT Consulting Services</span>
            <span className="service-item-plus">{dropdownVisible === 3 ? '-' : '+'}</span>
          </div>
          {dropdownVisible === 3 && (
            <div className="dropdown-content">
              <p>We offer expert advice on IT strategies, technology solutions, and system implementations.</p>
            </div>
          )}
          <div
            className={`service-item ${dropdownVisible === 4 ? 'active' : ''}`}
            onClick={() => toggleDropdown(4)}
          >
            <span>Cloud Computing Recruitment</span>
            <span className="service-item-plus">{dropdownVisible === 4 ? '-' : '+'}</span>
          </div>
          {dropdownVisible === 4 && (
            <div className="dropdown-content">
              <p>We find candidates with expertise in cloud infrastructure, migration, and management.</p>
            </div>
          )}
          <div
            className={`service-item ${dropdownVisible === 5 ? 'active' : ''}`}
            onClick={() => toggleDropdown(5)}
          >
            <span>Cybersecurity Recruitment</span>
            <span className="service-item-plus">{dropdownVisible === 5 ? '-' : '+'}</span>
          </div>
          {dropdownVisible === 5 && (
            <div className="dropdown-content">
              <p>We identify and place professionals with specialized skills in security analysis, penetration testing, and compliance.</p>
            </div>
          )}
          <div
            className={`service-item ${dropdownVisible === 6 ? 'active' : ''}`}
            onClick={() => toggleDropdown(6)}
          >
            <span>Data Analytics and Science Recruitment</span>
            <span className="service-item-plus">{dropdownVisible === 6 ? '-' : '+'}</span>
          </div>
          {dropdownVisible === 6 && (
            <div className="dropdown-content">
              <p>We source candidates with expertise in data analysis, machine learning, and business intelligence.</p>
            </div>
          )}
          <div
            className={`service-item ${dropdownVisible === 7 ? 'active' : ''}`}
            onClick={() => toggleDropdown(7)}
          >
            <span>Digital Transformation Services</span>
            <span className="service-item-plus">{dropdownVisible === 7 ? '-' : '+'}</span>
          </div>
          {dropdownVisible === 7 && (
            <div className="dropdown-content">
              <p>We help organizations find talent to support digital transformation initiatives.</p>
            </div>
          )}
          <div
            className={`service-item ${dropdownVisible === 8 ? 'active' : ''}`}
            onClick={() => toggleDropdown(8)}
          >
            <span>IT Support and Help Desk Recruitment</span>
            <span className="service-item-plus">{dropdownVisible === 8 ? '-' : '+'}</span>
          </div>
          {dropdownVisible === 8 && (
            <div className="dropdown-content">
              <p>We find candidates for IT support roles, including help desk technicians and support specialists.</p>
            </div>
          )}
          <div
            className={`service-item ${dropdownVisible === 9 ? 'active' : ''}`}
            onClick={() => toggleDropdown(9)}
          >
            <span>Emerging Technology Recruitment</span>
            <span className="service-item-plus">{dropdownVisible === 9 ? '-' : '+'}</span>
          </div>
          {dropdownVisible === 9 && (
            <div className="dropdown-content">
              <p>We identify talent with expertise in AI, blockchain, IoT, and other emerging technologies.</p>
            </div>
          )}
        </div>
      </section>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <p>© 2024 SkysSoft Solutions Inc</p>
        </div>
      </footer>
        </>
    );
}

export default Services;
